//@flow
import { QuestionTypeEnum } from "../../../Pages/QuestionTypes";
import { operation } from "../../../Pages/WispformTypings";
import BaseFormConfig from "./BaseFormConfig";
import { FinalPageType, PreviewListSection } from "./FormConfigEnums";
import { IFormConfig } from "./IFormConfig";

export default class OutcomeQuizConfig
  extends BaseFormConfig
  implements IFormConfig
{
  displayName = "Outcome Quiz";
  availableQuestionOperations = [operation.outcomeMapping];
  previewListSection = [
    PreviewListSection.QUESTION,
    PreviewListSection.OUTCOME
  ];
  finalPageType = FinalPageType.OUTCOMEPAGE;
  notSupportedContentTypes = [QuestionTypeEnum.ThankyouPage];
  creationModalConfig = {
    title: "Create an Outcome Quiz",
    inputPlaceholder: "new outcome quiz",
    description: `To begin creating an outcome quiz, please first give it a name 😊`,
    enableTemplate: false
  };
}
