//@flow
import * as React from "react";
import { connect } from "react-redux";
import withNotifications, {
  type InjectedProps
} from "../../../../../Helper_HOC/withNotifications";
import { browserHistory } from "react-router";
import { logFeatureEvent } from "../../../../../Library/Logger";
import { setCloseFormSetting } from "../../../../FormBuilderPage/States/actions";
import {
  UICoreText,
  UICoreImage,
  UICoreBox,
  UICoreButton,
  UICoreModal,
  UICoreInput,
  UICoreDropdown
} from "../../../../../Component/UICore";
import ToggleWrapper, { Toggle } from "../../ToggleWrapper";
import {
  getRequest,
  postRequest,
  deleteRequest
} from "../../../../../Library/Request";
import {
  resolveInTime,
  isNonEmptyString,
  safeGet
} from "../../../../../Library/Util";
import { getUrlVars } from "../../../../../Library";

type Props = {|
  ...InjectedProps,
  configurations: {
    close_form_setting: ?string,
    custom_close_form_text: ?string
  },
  setCloseFormSetting: (string, string) => void
|};

type State = {|
  notifications: any
|};

class RestrictAccessSection extends React.Component<Props, State> {
  state: State = {
    notifications: {}
  };

  constructor(props: Props) {
    super(props);
  }

  _handleCloseFormToggle = () => {
    if (
      this.props.configurations &&
      this.props.configurations.close_form_setting != ""
    ) {
      /* Turn off the toggle */
      if (
        this.props.configurations.custom_close_form_text &&
        this.props.configurations.custom_close_form_text != ""
      ) {
        this.props.setCloseFormSetting(
          "",
          this.props.configurations.custom_close_form_text
        );
      } else {
        this.props.setCloseFormSetting(
          "",
          "You can no longer access this form as the form creator has closed it already. Please reach out to the form creator."
        );
      }
    } else {
      /* Turn on the toggle */

      let currentDateTime = new Date().toUTCString();

      if (
        this.props.configurations &&
        this.props.configurations.custom_close_form_text &&
        this.props.configurations.custom_close_form_text != ""
      ) {
        this.props.setCloseFormSetting(
          currentDateTime,
          this.props.configurations.custom_close_form_text
        );
      } else {
        this.props.setCloseFormSetting(
          currentDateTime,
          "You can no longer access this form as the form creator has closed it already. Please reach out to the form creator."
        );
      }
    }
  };

  render() {
    return (
      <UICoreBox padding="sm" alignItems="start">
        <UICoreBox alignItems="start" width="100%">
          <UICoreBox paddingBottom="md">
            <UICoreText color="black" size="md" weight="bold">
              Restrict access to form
            </UICoreText>
          </UICoreBox>
          <UICoreBox width="275px" paddingBottom="md">
            <Toggle
              isOn={
                this.props.configurations &&
                this.props.configurations.close_form_setting &&
                this.props.configurations.close_form_setting != ""
                  ? true
                  : false
              }
              handleToggleClick={() => {
                this._handleCloseFormToggle();
              }}
              toggleName="Close form to new responses"
            />
          </UICoreBox>
          <UICoreBox paddingTop="xm">
            <UICoreText color="black" size="xs">
              Show custom closed message
            </UICoreText>
          </UICoreBox>
          <UICoreBox direction="row" alignItems="center">
            <UICoreBox paddingTop="xm">
              <UICoreInput
                onChange={(_, text) => {
                  this.props.setCloseFormSetting(
                    this.props.configurations.close_form_setting
                      ? this.props.configurations.close_form_setting
                      : "",
                    text
                  );
                }}
                width={200}
                size="xs"
                value={
                  this.props.configurations &&
                  this.props.configurations.custom_close_form_text
                    ? this.props.configurations.custom_close_form_text
                    : ""
                }
                disabled={
                  this.props.configurations &&
                  this.props.configurations.close_form_setting &&
                  this.props.configurations.close_form_setting != ""
                    ? false
                    : true
                }
              />
            </UICoreBox>
            <UICoreBox paddingTop="xm" paddingLeft="xm">
              <UICoreButton
                onClick={() => {
                  // this._resetButtonText(buttonConfig);
                  if (
                    this.props.configurations &&
                    this.props.configurations.close_form_setting
                  ) {
                    this.props.setCloseFormSetting(
                      this.props.configurations.close_form_setting,
                      "You can no longer access this form as the form creator has closed it already. Please reach out to the form creator."
                    );
                  }
                }}
                color="white"
                size="sm"
              >
                Reset
              </UICoreButton>
            </UICoreBox>
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    configurations: state.Question.configurations
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setCloseFormSetting: (close_form_setting, custom_close_form_text) => {
      dispatch(setCloseFormSetting(close_form_setting, custom_close_form_text));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNotifications(RestrictAccessSection));
