//@flow
import * as React from "react";
import AddButton from "./Components/AddButton";
import QuestionListContainer from "./Containers/QuestionListContainer";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import ViewWindow from "./Containers/ViewWindow";
import ToolBoxContainer from "./Containers/ToolBoxContainer";
import HeaderBarContainer from "./Containers/HeaderBarContainer";
import Modal from "../../Component/Modal";
import {
  fetchFormDetail,
  showWarning,
  hideWarning,
  saveToBackend
} from "./States/actions";
import { domainName } from "../../config";
import "./style.css";
import PropTypes from "prop-types";
import {
  type formStyleType,
  type backgroundCssType
} from "../../FlowTypes/wispformStyleTypes";
import WithStyles from "../../Helper_HOC/WithStyles";
import Drift from "react-driftjs";
import {
  closeOnboardingBackground,
  startOnboardingProcess,
  closeShareButtonTooltip
} from "./States/OnBoarding/actions";
import {
  type onBoardingStageType,
  ONBOARDING_STAGE
} from "./States/OnBoarding/reducer";
import Tooltip from "../../Component/Tooltip";
import { logNewUserOnboarding } from "../../Library/Logger";
import FormPreviewModal from "../../Component/FormPreviewModal";
import { UICoreBox, UICoreText } from "../../Component/UICore";
import { addHotJarRecording } from "../../Library/hotJar";

type Props = {
  fetchingFormDetail: boolean,
  fetchFormDetail: () => any,
  hideWarning: () => any,
  questions: Array<any>, //TODO: taoxiang: give more specific type of question,
  saveToBackend: () => any,
  shouldShowWarning: boolean,
  showWarning: () => any,
  styles: formStyleType,
  backgroundCss: backgroundCssType,
  showOnboardingBackground: boolean,
  onboardingStage: onBoardingStageType,
  closeOnboardingBackground: () => mixed,
  startOnboardingProcess: () => mixed,
  last_edited_question: number,
  closeShareButtonTooltip: () => mixed
};
type State = {
  showSelector: boolean,
  showPreview: boolean,
  shareButtonNode: ?HTMLElement
};

const focusedElementZIndex = "3";
let savedAddButtonBackgroundColor = null;

class FormBuilderPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showPreview: false,
      showSelector: false,
      shareButtonNode: null
    };
  }

  componentWillMount() {
    this.props.fetchFormDetail();
  }

  closeSelector() {
    this.hideSelector();
  }

  // componentDidMount() {
  //   addHotJarRecording();
  // }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.questions &&
      this.props.questions.length == 0 &&
      this.state.showSelector == false &&
      !this.props.fetchingFormDetail
    ) {
      this.showSelector();
    }
    this._handleOnboardingProcess();
  }

  _getShareButtonNode = () => {
    const shareButton = document.getElementsByClassName("GlobalNavBar-Link")[2];
    if (shareButton && !this.state.shareButtonNode) {
      this.setState({
        shareButtonNode: shareButton
      });
    }
  };

  _hasQuestionCreated = () => {
    return this.props.questions && this.props.questions.length > 0;
  };

  _hasOnboarded = () => {
    return localStorage.getItem("HAS_ONBOARDED");
  };

  _handleOnboardingProcess = () => {
    if (!this._hasQuestionCreated() && !this._hasOnboarded()) {
      this.props.startOnboardingProcess();
      localStorage.setItem("HAS_ONBOARDED", "true");
    } else if (this.props.showOnboardingBackground === true) {
      this.props.closeOnboardingBackground();
    }
    if (
      this.props.onboardingStage === ONBOARDING_STAGE.firstQuestionTitleTyped &&
      document.getElementsByClassName("AddButton")[0] &&
      !savedAddButtonBackgroundColor
    ) {
      //focus on add button if user has typed first question tittle
      savedAddButtonBackgroundColor = getComputedStyle(
        document.getElementsByClassName("AddButton")[0]
      ).backgroundColor;
      document.getElementsByClassName("AddButton")[0].style.backgroundColor =
        "#007aff";
      document.getElementsByClassName("AddButton")[0].style.zIndex = "3";
      //show question body after title typed
      document.getElementsByClassName("ViewWindow-Question")[0].style.zIndex =
        "3";
    }

    if (
      this.props.onboardingStage === ONBOARDING_STAGE.secondQuestionSelected &&
      document.getElementsByClassName("AddButton")[0] &&
      savedAddButtonBackgroundColor
    ) {
      //set back add button color
      document.getElementsByClassName("AddButton")[0].style.backgroundColor =
        savedAddButtonBackgroundColor;
      //get sharebutton anchor for the tooltip
      this._getShareButtonNode();
    }
  };

  showSelector() {
    this.setState({
      showSelector: true
    });
  }

  hideSelector() {
    this.setState({
      showSelector: false
    });
  }

  showPreview() {
    this.props.saveToBackend();
    logNewUserOnboarding("previewButtonClicked");
    sleep(500).then(() => {
      this.setState({
        showPreview: true
      });
    });
  }

  _closePreviewModal = () => {
    this.setState({
      showPreview: false
    });
  };

  showPreviewScreen() {
    if (this.state.showPreview) {
      this.props.saveToBackend();
      return <FormPreviewModal onDismiss={this._closePreviewModal} />;
    } else {
      return "";
    }
  }

  _handleShareTooltipClose = () => {
    this.props.closeShareButtonTooltip();
    logNewUserOnboarding("shareButtonTooltipClosed");
  };

  _renderShareTooltip = () => {
    if (
      this.props.onboardingStage === ONBOARDING_STAGE.secondQuestionSelected
    ) {
      return (
        <Tooltip
          after_close={this._handleShareTooltipClose}
          className="FormBuilderPage-Onboarding-Share"
          anchor={this.state.shareButtonNode}
          direction="top"
        >
          Share your form when you are ready
        </Tooltip>
      );
    }
  };

  renderModal() {
    return (
      <Modal
        type="Warning"
        showModal={this.props.shouldShowWarning}
        hideModal={() => {
          this.props.hideWarning();
          browserHistory.push("/");
        }}
        ModalName="ERROR"
        ClassModalContainerBackground="dashboard-modal-background"
        action1="RETURN"
        onAction1Clicked={() => {
          this.props.hideWarning();
          browserHistory.push("/Workspace/0");
        }}
      >
        <div style={{ padding: "5px 20px 0px 20px" }}>
          Something is wrong with the server, please contact us if error
          persists.
        </div>
      </Modal>
    );
  }

  windowStyle() {
    return {
      ...this.props.backgroundCss
    };
  }

  _renderOnboardingBackground = () => {
    return this.props.showOnboardingBackground ? (
      <div className="FormBuilderPage-OnbiardingBackground">
        {
          //Todo: @taoxiang decide if we allow users to quit onboarding or not
          // <i
          //   onClick={() => {
          //     this.props.closeOnboardingBackground();
          //   }}
          //   className="FormBuilderPage-OnbiardingBackground-Close ion-close"
          // />
        }
        {this.props.onboardingStage === ONBOARDING_STAGE.emptyBuilder && (
          <div className="FormBuilderPage-Onboarding-QuestionSelector FormBuilderPage-Onboarding-Text">
            ① Select a type of question you'd like to ask 📣
          </div>
        )}
        {this.props.onboardingStage ===
          ONBOARDING_STAGE.firstQuestionSelected && (
          <div className="FormBuilderPage-Onboarding-QuestionEditor-Edit FormBuilderPage-Onboarding-Text">
            ② Type the question you'd like to ask ⌨️ ️
          </div>
        )}
        {this.props.onboardingStage ===
          ONBOARDING_STAGE.firstQuestionTitleTyped && (
          <div className="FormBuilderPage-Onboarding-QuestionEditor-Add FormBuilderPage-Onboarding-Text">
            ③ Well done 🎉 Click to add second question
          </div>
        )}
      </div>
    ) : null;
  };

  render() {
    return (
      <div onClick={this.closeSelector.bind(this)} className="FormBuilderPage">
        <Drift appId="cdaz2rv7gfxd" />
        {this.showPreviewScreen()}
        {this.renderModal()}
        <div className="FormBuilderPage-MainContent row">
          <div className="FormBuilderPage-MainContent-PreviewWindow col-xs-2">
            <QuestionListContainer
              show_selector={this.state.showSelector}
              hideSelector={this.hideSelector.bind(this)}
              showSelector={this.showSelector.bind(this)}
            />
          </div>
          <div className="FormBuilderPage-MainContent-ViewWindow col-xs-8">
            <div className="FormBuilderPage-HeaderBar">
              <HeaderBarContainer showPreview={this.showPreview.bind(this)} />
            </div>
            <div className="FormBuilderPage-MainContent-ViewWindow-Window">
              <div
                style={this.windowStyle()}
                className="FormBuilderPag-ViewWindow-Container"
              >
                <ViewWindow />
              </div>
            </div>
          </div>
          <div className="FormBuilderPage-MainContent-ToolBox col-xs-2">
            <ToolBoxContainer />
          </div>
        </div>
        {this._renderOnboardingBackground()}
        {this._renderShareTooltip()}
      </div>
    );
  }
}

function sleep(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

function mapStateToProps(state) {
  return {
    shouldShowWarning: state.Question.warning,
    questions: state.Question.questions,
    last_edited_question: state.Question.last_edited_question,
    showOnboardingBackground: state.OnBoarding.showOnboardingBackground,
    onboardingStage: state.OnBoarding.onboardingStage,
    fetchingFormDetail: state.Question.fetchingFormDetail
  };
}

const mapDispatchToProps = {
  fetchFormDetail,
  hideWarning,
  showWarning,
  saveToBackend,
  saveToBackend,
  closeOnboardingBackground,
  startOnboardingProcess,
  closeShareButtonTooltip
};

FormBuilderPage.propTypes = {
  fetchFormDetail: PropTypes.func.isRequired,
  shouldShowWarning: PropTypes.bool.isRequired,
  hideWarning: PropTypes.func.isRequired,
  showWarning: PropTypes.func.isRequired,
  saveToBackend: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithStyles(FormBuilderPage));
