// @flow
import { browserHistory } from "react-router";
import {
  SHOW_ACCOUNT_UPGRADE_MODAL,
  CLOSE_ACCOUNT_UPGRADE_MODAL
} from "../../Pages/FormBuilderPage/States/AccountUpgradeModal/actions";
import {
  isPro,
  isPremium,
  isBusiness,
  isEssential
} from "../../Library/UserInfo";
import { toArray, isNonEmptyString } from "../../Library/Util";
import { logMonetizationEvent } from "../../Library/Logger";
import { postRequest } from "../../Library/Request";
import {
  saveToBackend,
  TOGGLE_SET_SCORE_MODAL,
  UPDATE_STYLE_BACKGROUND,
  ADD_LOGO,
  UPDATE_HIDDEN_FIELDS,
  ADD_ANSWER_PIPE
} from "../../Pages/FormBuilderPage/States/actions";
import { type QuestionsStateType } from "../../Pages/FormBuilderPage/States/reducer";
import { PAYMENT, QuestionTypeEnum } from "../../Pages/QuestionTypes";
import {
  hasScoreSetting,
  hasHiddenFieldVariables
} from "../../Pages/FormBuilderPage/States/selectors";
import {
  backgroundType,
  type background
} from "../../FlowTypes/wispformStyleTypes";
import { FormTypeEnum } from "../../FlowTypes/forms";
import { getBackgroundImageWithAutoPlatformDetection } from "../../Library/FormStyleDataStructureConversion";
import { isWhitelistedBackgroundImage } from "../../Helper_HOC/WithFeatureCheck/whitelistedImages";

/**
 * sometimes a feature requires multiple actions to complete.
 * set this flag to true would queue all the subsequent actions until user has accepted/declined upsell.
 */
let _shouldQueueSubsequentActions = false;
let _subsequentActionQueues = [];

let _hasAddedAnswerPipe = false;

const UpgradeModalMiddleware =
  (store: { getState: () => { Question: QuestionsStateType } }) =>
  (next: any) =>
  (action: any) => {
    let actionName = "";
    if (typeof action === "function") {
      actionName = action.name;
    } else {
      actionName = action.type;
    }

    const questionState: QuestionsStateType = store.getState().Question;

    //if is paid account, skip checking
    if (isPro() || isPremium() || isBusiness() || isEssential()) {
      return next(action);
    }
    switch (actionName) {
      case "NEW_FORM":
        if (action.formType === FormTypeEnum.Classic) {
          return next(action);
        } else {
          if (action.formType === FormTypeEnum.OutcomeQuiz) {
            logMonetizationEvent("modalViewed-OutcomeQuiz");
          } else {
            logMonetizationEvent("modalViewed-Calculator");
          }
          return next({
            type: SHOW_ACCOUNT_UPGRADE_MODAL,
            featureName:
              action.formType === FormTypeEnum.OutcomeQuiz
                ? "Outcome Quiz"
                : "Calculator",
            featureAction: () => {
              if (action.formType === FormTypeEnum.OutcomeQuiz) {
                logMonetizationEvent("tryClicked-OutcomeQuiz");
              } else {
                logMonetizationEvent("tryClicked-Calculator");
              }
              postRequest("api/v1/forms", {
                name: action.name ? action.name : "",
                workspace_index: action.workspaceIndex,
                form_type: action.formType
              }).then(response => {
                browserHistory.push(
                  `/form/formbuilder?id=${response.data.data.forms.uuid}`
                );
              });
              // No catch for this function
              // .catch(function (error) {
              //   dispatch(showWarning());
              // });
              next(action);
            }
          });
        }

      case "ADD_THANKYOU_PAGE":
        logMonetizationEvent("modalViewed-Thankyou Page");
        return next({
          type: SHOW_ACCOUNT_UPGRADE_MODAL,
          featureName: "Thankyou Page",
          featureAction: () => {
            next(action);
            next(saveToBackend());
            logMonetizationEvent("tryClicked-Thankyou Page");
          }
        });

      case ADD_LOGO:
        //remove logo shouldn't trigger any modal
        if (action.url == null) {
          return next(action);
        } else {
          // $FlowFixMe
          logMonetizationEvent("modalViewed-AddBrandLogo");
          return next({
            type: SHOW_ACCOUNT_UPGRADE_MODAL,
            featureName: "Branding",
            featureAction: () => {
              next(action);
              next(saveToBackend());
              // $FlowFixMe
              logMonetizationEvent("tryClicked-Branding");
            }
          });
        }

      case "ADD_QUESTION_TYPE":
        return handleAddQuestion(store, next, action);

      case UPDATE_STYLE_BACKGROUND:
        const backgroundFromState =
          questionState &&
          questionState.styles &&
          questionState.styles.background;
        if (
          isFirstTimeAddingBackgroundImage(
            action.background,
            backgroundFromState
          )
        ) {
          return next({
            type: SHOW_ACCOUNT_UPGRADE_MODAL,
            featureName: "Background Image",
            featureAction: () => {
              next(action);
              next(saveToBackend());
              // $FlowFixMe
              logMonetizationEvent("tryClicked-BackgroundIamge");
            }
          });
        } else {
          return next(action);
        }

      case UPDATE_HIDDEN_FIELDS:
        if (!hasHiddenFieldVariables(store.getState().Question)) {
          // $FlowFixMe
          logMonetizationEvent("modalViewed-Hidden Fields");
          return next({
            type: SHOW_ACCOUNT_UPGRADE_MODAL,
            featureName: "Hidden Fields",
            featureAction: () => {
              next(action);
              next(saveToBackend());
              // $FlowFixMe
              logMonetizationEvent("tryClicked-Hidden Fields");
            }
          });
        }
        return next(action);

      case TOGGLE_SET_SCORE_MODAL:
        if (action.show && !hasScoreSetting(store.getState().Question)) {
          // $FlowFixMe
          logMonetizationEvent("modalViewed-Score Calculation");
          return next({
            type: SHOW_ACCOUNT_UPGRADE_MODAL,
            featureName: "Score Calculation",
            featureAction: () => {
              next(action);
              next(saveToBackend());
              // $FlowFixMe
              logMonetizationEvent("tryClicked-Score Calculation");
            }
          });
        }
        return next(action);

      case ADD_ANSWER_PIPE:
        if (!_hasAddedAnswerPipe) {
          _setShouldEnqueueSubsequentActions();
          return next({
            type: SHOW_ACCOUNT_UPGRADE_MODAL,
            featureName: "Answer Piping",
            featureAction: () => {
              _hasAddedAnswerPipe = true;
              _shouldQueueSubsequentActions = false;
              _subsequentActionQueues.forEach(action => next(action));
              next(saveToBackend());
              // $FlowFixMe
              logMonetizationEvent("tryClicked-Answer Pipe");
            }
          });
        } else {
          next(action);
        }

      case CLOSE_ACCOUNT_UPGRADE_MODAL:
        _shouldQueueSubsequentActions = false;
        return next(action);

      default:
        if (_shouldQueueSubsequentActions) {
          _subsequentActionQueues.push(action);
        } else {
          return next(action);
        }
    }
  };

function isFirstTimeAddingBackgroundImage(
  backgroundFromAction: background,
  backgroundFromState: background
) {
  //Updating background image of a template would be considered as first time adding image.
  const noExistingNonWhitelistedBackgroundImage =
    !isNonEmptyString(
      getBackgroundImageWithAutoPlatformDetection(backgroundFromState)
    ) ||
    isWhitelistedBackgroundImage(
      getBackgroundImageWithAutoPlatformDetection(backgroundFromState)
    );

  const tryingToAddBackgroundImage = isNonEmptyString(
    getBackgroundImageWithAutoPlatformDetection(backgroundFromAction)
  );

  const isSettingImage = backgroundFromAction.type === backgroundType.image;

  return (
    isSettingImage &&
    tryingToAddBackgroundImage &&
    noExistingNonWhitelistedBackgroundImage
  );
}

function handleAddQuestion(store, next, action) {
  const maxFreeQuestions = 10;
  const questionState = store.getState().Question;
  const questionNumber = toArray(questionState.questions).length;
  if (action.question && action.question.type === PAYMENT) {
    // $FlowFixMe
    logMonetizationEvent("modalViewed-Payment");
    next({
      type: SHOW_ACCOUNT_UPGRADE_MODAL,
      featureName: "Payment field",
      featureAction: () => {
        next(action);
        next(saveToBackend());
        // $FlowFixMe
        logMonetizationEvent("tryClicked-Payment");
      }
    });
  } else if (
    action.question &&
    action.question.type === QuestionTypeEnum.Subscription
  ) {
    // $FlowFixMe
    logMonetizationEvent("modalViewed-Subscription");
    next({
      type: SHOW_ACCOUNT_UPGRADE_MODAL,
      featureName: "Subscription question",
      featureAction: () => {
        next(action);
        next(saveToBackend());
        // $FlowFixMe
        logMonetizationEvent("tryClicked-Subscription");
      }
    });
  } else if (questionNumber === maxFreeQuestions) {
    logMonetizationEvent("modalViewed-Create more than 10 questions");
    next({
      type: SHOW_ACCOUNT_UPGRADE_MODAL,
      featureName: "Create more than 10 questions",
      featureAction: () => {
        next(action);
        next(saveToBackend());
        logMonetizationEvent("tryClicked-Create more than 10 questions");
      }
    });
  } else {
    next(action);
  }
}

function _setShouldEnqueueSubsequentActions() {
  _shouldQueueSubsequentActions = true;

  // As a safe net, we should always set this back
  setTimeout(_ => {
    _shouldQueueSubsequentActions = false;
  }, 1000);
}

export default UpgradeModalMiddleware;
