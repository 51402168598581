//@flow

import { type formPlan } from "../Pages/WispformTypings";
import WebStorage from "../Library/WebStorage";
import { safeGet } from "./Util";
import { getUrlVars } from ".";

export opaque type stripeID = string;
export type userInfoType = {|
  email: string,
  name: string,
  id: number,
  plan: formPlan,
  connected_stripe_account_id: stripeID,
  organization_name?: string,
  organization_role?: string
|};

export type orgInfoType = {|
  name: string,
  plan: formPlan,
  connected_stripe_account_id: stripeID
|};

let _userInfo: ?userInfoType = null;
const USER_INFO_KEY = "userInfo";

export function saveUserInfo(publisher: userInfoType) {
  try {
    _userInfo = {
      email: publisher.email,
      name: publisher.name,
      id: publisher.id,
      plan: publisher.plan,
      connected_stripe_account_id: publisher.connected_stripe_account_id
    };
    const userInfoString = JSON.stringify(_userInfo);
    WebStorage.saveToWebStorage(USER_INFO_KEY, userInfoString);
  } catch (e) {
    console.error(e);
  }
}

export function saveUserInfoWithOrg(
  publisher: userInfoType,
  organization: orgInfoType,
  organization_role: string
) {
  try {
    _userInfo = {
      email: publisher.email,
      name: publisher.name,
      id: publisher.id,
      plan: organization.plan,
      connected_stripe_account_id: organization.connected_stripe_account_id,
      organization_name: organization.name,
      organization_role: organization_role
    };
    const userInfoString = JSON.stringify(_userInfo);
    WebStorage.saveToWebStorage(USER_INFO_KEY, userInfoString);
  } catch (e) {
    console.error(e);
  }
}

export function updateUserInfo(fields: $Shape<userInfoType>) {
  try {
    _userInfo = {
      ..._userInfo,
      ...fields
    };
    const userInfoString = JSON.stringify(_userInfo);
    WebStorage.saveToWebStorage(USER_INFO_KEY, userInfoString);
  } catch (e) {
    console.error(e);
  }
}

export function getUserInfo(): ?userInfoType {
  if (_userInfo) {
    return _userInfo;
  }
  //not saved in session memory, get it from localstorage
  else {
    const userInfoString = WebStorage.getFromWebStorage(USER_INFO_KEY);
    let userInfo: ?userInfoType = null;
    if (userInfoString) {
      userInfo = JSON.parse(userInfoString);
    }
    _userInfo = userInfo;
    return _userInfo;
  }
}

export function getUserName(): string {
  const userInfo = getUserInfo();
  return userInfo ? userInfo.name : "";
}

export function getUserEmail(): string {
  const userInfo = getUserInfo();
  return userInfo ? userInfo.email : "";
}

export function getUserID(): ?number {
  const userInfo = getUserInfo();
  return userInfo ? userInfo.id : null;
}

export function isDeveloper(): boolean {
  const developerIDs = [22, 40, 5, 109, 2, 3];
  const userID = getUserID();
  return developerIDs.includes(userID);
}

export function isEssential(): boolean {
  const userInfo = getUserInfo();
  if (!userInfo) return false;
  return userInfo.plan === "Essential";
}

export function isPro(): boolean {
  const userInfo = getUserInfo();
  if (!userInfo) return false;
  return userInfo.plan === "Pro";
}

export function isPremium(): boolean {
  const userInfo = getUserInfo();
  if (!userInfo) return false;
  return userInfo.plan === "Premium";
}

export function isBusiness(): boolean {
  const userInfo = getUserInfo();
  if (!userInfo) return false;
  return userInfo.plan === "Business";
}

export function isDisabled(): boolean {
  const userInfo = getUserInfo();
  if (!userInfo) return false;
  return userInfo.plan === "Disabled";
}

export function isPaidUser(): boolean {
  return isEssential() || isPro() || isPremium() || isBusiness();
}

export function isProOrAbove(): boolean {
  return isPro() || isPremium() || isBusiness();
}

export function hasConnectedWithStripe(): boolean {
  const userInfo = getUserInfo();
  if (!userInfo) return false;
  if (userInfo.connected_stripe_account_id) {
    return true;
  } else {
    return false;
  }
}

export function isInOrganization(): boolean {
  const userInfo = getUserInfo();
  if (!userInfo) return false;
  if (!userInfo.organization_name) return false;
  if (!userInfo.organization_role) return false;
  return true;
}

export function isEditorInOrganization(): boolean {
  const userInfo = getUserInfo();
  if (!userInfo) return false;
  if (!userInfo.organization_name) return false;
  if (!userInfo.organization_role) return false;
  if (userInfo.organization_role === "Editor") {
    return true;
  } else {
    return false;
  }
}

export function isAdminInOrganization(): boolean {
  const userInfo = getUserInfo();
  if (!userInfo) return false;
  if (!userInfo.organization_name) return false;
  if (!userInfo.organization_role) return false;
  if (userInfo.organization_role == "Admin") {
    return true;
  } else {
    return false;
  }
}

export function getFormUid(): string {
  return String(safeGet(_ => getUrlVars()["id"]) || "");
}
