//@flow

const kWispformGtagID = "GTM-WLT42B5";

function initGoogleTagManagerWithIDFactory() {
  let initialized: boolean = false;
  return (gtmContainerID: string) => {
    if (!initialized) {
      addGTMScriptToHeader(gtmContainerID);
      addGTMFrameToBody(gtmContainerID);
      initialized = true;
    }
  };
}

function addGTMScriptToHeader(gtmContainerID: string) {
  var script = document.createElement("script");
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${String(gtmContainerID).trim()}');`;
  document.getElementsByTagName("head")[0].appendChild(script);
}

function addGTMFrameToBody(gtmContainerID: string) {
  var noscript = document.createElement("noscript");
  var iframe = document.createElement("iframe");
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmContainerID}`;
  iframe.height = "0";
  iframe.width = "0";
  iframe.style.display = "none";
  iframe.style.visibility = "hidden";
  noscript.appendChild(iframe);
  document.getElementsByTagName("body")[0].appendChild(noscript);
}

export function gtagReportPurchaseConversion() {
  try {
    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-11492341784/TIZYCP_z0fYZEJjw_Ocq",
        transaction_id: "",
        event_callback: () => {}
      });
    }
  } catch (error) {
    return false;
  }
  return true;
}

export function initGoogleTagManagerWithWispformID() {
  const factory = initGoogleTagManagerWithIDFactory();
  factory(kWispformGtagID);
}

export default initGoogleTagManagerWithIDFactory();
