//@flow
import * as React from "react";
import { connect } from "react-redux";
import withNotifications, {
  type InjectedProps
} from "../../../../../Helper_HOC/withNotifications";
import { browserHistory } from "react-router";
import { logFeatureEvent } from "../../../../../Library/Logger";
import { isPaidUser } from "../../../../../Library/UserInfo";
import {
  UICoreText,
  UICoreImage,
  UICoreBox,
  UICoreButton,
  UICoreModal,
  UICoreInput,
  UICoreDropdown
} from "../../../../../Component/UICore";
import ToggleWrapper, { Toggle } from "../../ToggleWrapper";
import {
  getRequest,
  postRequest,
  deleteRequest
} from "../../../../../Library/Request";
import {
  resolveInTime,
  isNonEmptyString,
  safeGet
} from "../../../../../Library/Util";
import { getUrlVars } from "../../../../../Library";
import { listEmailQuestions } from "../../../States/selectors";

type Props = {| ...InjectedProps, emailQuestions: Array<any> |};

type State = {|
  notifications: any
|};

class RespondentEmailSection extends React.Component<Props, State> {
  state: State = {
    notifications: {}
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    let form_id = getUrlVars()["id"];
    getRequest("api/v1/forms/" + form_id + "/notifications", {
      form_uuid: form_id
    }).then(notification => {
      if (notification.data.data && notification.data.data.notifications)
        this.setState({
          notifications: notification.data.data.notifications
        });
      else {
        this.setState({ notifications: {} });
      }
    });
  }

  _handleEnableRespondentNotificationToggle = () => {
    if (
      this.state.notifications &&
      this.state.notifications.enableRespondentNotification
    ) {
      this._handleNotificationUpdate(
        !this.state.notifications.enableRespondentNotification,
        ""
      );
    } else {
      this._handleNotificationUpdate(true, "");
    }
  };

  _handleRespondentNotificationEmailQuestionUpdate = question_id => {
    if (question_id) {
      this._handleNotificationUpdate(
        this.state.notifications.enableRespondentNotification,
        question_id
      );
    } else {
      this._handleNotificationUpdate(
        this.state.notifications.enableRespondentNotification,
        ""
      );
    }
  };

  _handleNotificationUpdate = (
    enableRespondentNotification: boolean,
    respondentNotificationEmailQuestionID: string
  ) => {
    if (!isPaidUser()) {
      this.props.showNotification(
        "primary",
        "Adding redirect URL on submission is a paid feature",
        "Upgrade to any paid plan to redirect users on submission",
        "Upgrade",
        () => {
          logFeatureEvent("configurePage/addRedirectURLUpgradeClicked");
          browserHistory.push("/setting/checkout");
        }
      );
    } else {
      let new_notifications = this.state.notifications;
      new_notifications.enableRespondentNotification =
        enableRespondentNotification;
      new_notifications.respondentNotificationEmailQuestionID =
        respondentNotificationEmailQuestionID;
      this.setState({ notifications: new_notifications });
      let form_id = getUrlVars()["id"];
      postRequest("api/v1/forms/" + form_id + "/notifications", {
        form_uuid: form_id,
        notifications: this.state.notifications
      });
    }
  };

  _getEmailQuestionByID = question_id => {
    let result = this.props.emailQuestions.find(
      q =>
        q.question_id ===
        this.state.notifications.respondentNotificationEmailQuestionID
    );
    return result && result.title ? result.title : "";
  };

  _getSelectedChoice = () => {
    if (
      isNonEmptyString(
        safeGet(
          _ => this.state.notifications.respondentNotificationEmailQuestionID
        )
      )
    ) {
      return {
        value: this.state.notifications.respondentNotificationEmailQuestionID,
        display: this._getEmailQuestionByID(
          this.state.notifications.respondentNotificationEmailQuestionID
        )
      };
    }
    return null;
  };

  render() {
    return (
      <UICoreBox padding="sm" alignItems="start">
        <UICoreBox alignItems="start" width="100%">
          <UICoreBox paddingBottom="md">
            <UICoreText color="black" size="md" weight="bold">
              Respondent Notification
            </UICoreText>
          </UICoreBox>
          <UICoreBox paddingBottom="md">
            <UICoreText overflow="wrap">
              Turning on respondent notification will send a summary email to
              each of your responder after they submitted their answers. For
              this to work, you must also have an email question in your form
              and select that question in the dropdown below so we know which
              email to send this notification to.
            </UICoreText>
          </UICoreBox>
          <UICoreBox width="275px" paddingBottom="md">
            <Toggle
              isOn={
                this.state.notifications.enableRespondentNotification
                  ? this.state.notifications.enableRespondentNotification
                  : false
              }
              handleToggleClick={() => {
                this._handleEnableRespondentNotificationToggle();
              }}
              toggleName="Enable respondent notification"
            />
          </UICoreBox>
          <UICoreBox paddingBottom="md">
            <UICoreDropdown
              placeholder={
                this.props.emailQuestions.length == 0
                  ? "You don't have any email question"
                  : "Choose an email question"
              }
              selectChoice={this._getSelectedChoice()}
              dropdownMaxHeight={200}
              choices={this.props.emailQuestions.map(x => ({
                value: x.question_id,
                display: x.title
              }))}
              onChoiceClick={answer =>
                this._handleRespondentNotificationEmailQuestionUpdate(answer)
              }
              width="250px"
              dropdownWidth={350}
              disabled={
                this.state.notifications.enableRespondentNotification
                  ? !this.state.notifications.enableRespondentNotification
                  : true
              }
            />
            {/*                 
            <UICoreText weight="bold" color="lightBlue">
              Frequency:
            </UICoreText>
            <UICoreBox paddingTop="sm">
              <UICoreDropdown
                selectChoice={{
                  value: this.state.notifications.self_notification_frequency
                    ? this.state.notifications.self_notification_frequency
                    : "Daily",
                  display: this.state.notifications.self_notification_frequency
                    ? this.state.notifications.self_notification_frequency
                    : "Daily"
                }}
                dropdownMaxHeight={200}
                choices={[
                  { value: "Daily", display: "Daily" },
                  { value: "Each Response", display: "Each Response" }
                ]}
                width="150px"
                dropdownWidth={150}
                onChoiceClick={answer => this._handleFrequencyUpdate(answer)}
              />
            </UICoreBox> */}
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    emailQuestions: listEmailQuestions(state.Question),
    configurations: state.Question.configurations
  };
};

export default connect(mapStateToProps)(
  withNotifications(RespondentEmailSection)
);
