//@flow
import React, { Component } from "react";
import UI_Description from "../../../../Component/QuestionComponents/UI_Description";
import UI_QuestionTitle from "../../../../Component/QuestionComponents/UI_QuestionTitle";
import Question from "../Question";
import {
  setActiveQuestion,
  handleScroll,
  scrollToQuestion,
  scrollToSubmitButton
} from "./ScrollView";
import { isMobile } from "../../../../Component/Views/helper";
import {
  translateToQuestion,
  handleMove,
  translateToSubmitButton,
  hideButton,
  registerButtonHandler,
  adjustInputPositionWhenKeyboardIsUpOnNonIosDevice
} from "./MobileScrollView";
import SubmitButton from "../SubmitButton";
import "./style.css";
import "./style.mobile.css";
import logo from "../../../../asset/poweredby.png";
import { wispfromLandingPage } from "../../../../config";
import { logReferralEvent } from "../../../../Library/Logger";
import withStyles from "../../../../Helper_HOC/WithStyles";
import { type formStyleType } from "../../../../FlowTypes/wispformStyleTypes";
import UICoreImage from "../../../../Component/UICore/UICoreImage";
import { UICoreBox } from "../../../../Component/UICore";
import {
  toArray,
  isNonEmptyString,
  delayOneCycle
} from "../../../../Library/Util";
import WebStorage from "../../../../Library/WebStorage";
import QuestionMediaRender from "../../../../Component/QuestionMediaRender";
import { isInIframe } from "../../../../Library/Window";
import {
  reportQuestionViewIfNecessary,
  hasQuestionReported
} from "../../../../Library/QuestionViewReporting";
import { update_viewed_questions } from "../../States/Answers/actions";

type Props = {|
  nextQuestion: () => mixed,
  previousQuestion: () => mixed,
  set_current_question: number => mixed,
  isSubmitting: boolean,
  currentQuestionNumber: number,
  //TODO: @taoxiang make flow type more specific
  questions?: Array<Object>,
  invalid_answers: { [number]: Array<string> },
  submit_answer: any => any,
  update_answer: (any, boolean) => any,
  update_viewed_questions: any => any,
  viewed_questions: any,
  update_answer_without_update_current_question_index: any => any,
  styles: formStyleType,
  submitForm: () => mixed,
  answerSecondaryColor: string,
  isFormFromPaidPlan: boolean,
  isFormFromProOrHigherPlan: boolean
|};

class Questions extends Component<Props> {
  constructor(props) {
    super(props);
  }

  _onActiveQuestionChange = (questionIndex: number) => {
    let question = toArray(this.props.questions)[questionIndex];
    if (question && !hasQuestionReported(question)) {
      this.props.update_viewed_questions(question);
      delayOneCycle(_ => {
        reportQuestionViewIfNecessary(question, this.props.viewed_questions);
      });
    }
  };

  componentDidMount() {
    if (isMobile()) {
      setActiveQuestion(0, this._onActiveQuestionChange);
      translateToQuestion(0);
      handleMove(this.props.nextQuestion, this.props.previousQuestion);
      adjustInputPositionWhenKeyboardIsUpOnNonIosDevice();
    }
    //is desktop
    else {
      setActiveQuestion(0, this._onActiveQuestionChange);
      scrollToQuestion(0);
      handleScroll(
        this.props.set_current_question,
        this._onActiveQuestionChange
      );
    }

    // A fix to address an embed form issue
    // Where the form is initially rendered inside of a 0 height iframe
    // and later set the height to the screen.
    // Without the listner, the first question will not be scrolled to the center of the view
    if (this._shouldRegisterWindowResizeListner()) {
      window.addEventListener("resize", this._handleWindowResize);
    }

    this.logStartTime();
  }

  componentWillUnmount() {
    if (this._shouldRegisterWindowResizeListner()) {
      window.removeEventListener("resize", this._handleWindowResize);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (isMobile()) {
      if (this.isEndOfQuestions(prevProps)) {
        translateToSubmitButton();
      } else {
        setActiveQuestion(
          this.props.currentQuestionNumber - 1,
          this._onActiveQuestionChange
        );
        if (
          prevProps.currentQuestionNumber != this.props.currentQuestionNumber
        ) {
          translateToQuestion(this.props.currentQuestionNumber - 1);
        }
      }
    } else {
      /**
       * if isSubmitting is true, it means user clicked on the submit button.
       * that means user are already at the bottom of the form,
       * thus, no need to scroll again (to prevent validation navigate back glitch)
       */
      const hasCurrentQuestionUpdated =
        this.props.currentQuestionNumber !== prevProps.currentQuestionNumber;
      if (
        this.isEndOfQuestions(prevProps) &&
        hasCurrentQuestionUpdated &&
        !this.props.isSubmitting
      ) {
        scrollToSubmitButton();
      } else {
        setActiveQuestion(
          this.props.currentQuestionNumber - 1,
          this._onActiveQuestionChange
        );
        if (
          prevProps.currentQuestionNumber != this.props.currentQuestionNumber
        ) {
          scrollToQuestion(this.props.currentQuestionNumber - 1);
        }
      }
    }
  }

  _shouldRegisterWindowResizeListner = () => {
    return isInIframe() && this.props.currentQuestionNumber === 1;
  };

  _handleWindowResize = () => {
    translateToQuestion(this.props.currentQuestionNumber - 1);
  };

  logStartTime() {
    const date = new Date();
    const start_time = date.getTime();
    WebStorage.saveToWebStorage("start_time", start_time.toString());
  }

  isEndOfQuestions(prevProps) {
    if (
      this.props.questions &&
      this.props.questions.length < this.props.currentQuestionNumber &&
      //accidently triggered submit button on mobile when jump back & shrink the visible question queue, so current question number out grows questions number

      //we can prevent this from happending by comparing the szie of question queue before and after update. if they are at different size, meaining a jump logic update is in place. so we should not consider it as the end of question
      //no prevProps, meaning user click on submit area, so no need to check
      (!prevProps ||
        (prevProps &&
          toArray(prevProps.questions).length ===
            toArray(this.props.questions).length))
    ) {
      return true;
    } else {
      return false;
    }
  }

  getMessage(questionID) {
    if (this.props.invalid_answers[questionID]) {
      return this.props.invalid_answers[questionID];
    } else {
      return [];
    }
  }

  renderWarning(questionID) {
    const messages = this.getMessage(questionID);
    return messages.map(message => {
      return <div className="Question-Warning">{message}</div>;
    });
  }

  renderQuestions() {
    const {
      questions,
      submit_answer,
      currentQuestionNumber,
      nextQuestion,
      previousQuestion,
      update_answer,
      update_answer_without_update_current_question_index
    } = this.props;
    return (
      questions &&
      questions.map((question, index) => {
        return (
          <div id={"wispform" + index} className="Question">
            <div className="Question-Warning-Wrapper">
              {this.renderWarning(question.question_id)}
            </div>

            {/* MEDIA */}
            <UICoreBox paddingBottom="sm">
              <QuestionMediaRender question={question} />
            </UICoreBox>

            {/* TITLE */}
            <UICoreBox>
              <UI_QuestionTitle
                titleContent={question.title}
                QuestionType={question.type}
                styles={this.props.styles}
                questionNumberBase0={index}
              />
            </UICoreBox>

            {/* DESCRIPTION */}
            {isNonEmptyString(question.description) && (
              <UICoreBox paddingTop="sm">
                <UI_Description
                  description={question.description}
                  styles={this.props.styles}
                />
              </UICoreBox>
            )}

            {/* QUESTION BODY */}
            <UICoreBox paddingTop="md">
              <Question
                submit_answer={submit_answer}
                update_answer={update_answer}
                update_answer_without_update_current_question_index={
                  update_answer_without_update_current_question_index
                }
                className="UI-mainContent-QuestionBody-QuestionContainer"
                currentQuestionNumber={currentQuestionNumber}
                questions={questions ? questions : null}
                questionID={question.question_id}
                question_index={index + 1}
                nextQuestion={nextQuestion}
                previousQuestion={previousQuestion}
              />
            </UICoreBox>
          </div>
        );
      })
    );
  }

  handleSubmitAreaClick(e) {
    e.stopPropagation();
    registerButtonHandler(
      this.isEndOfQuestions.bind(this),
      this.props.previousQuestion
    );
  }

  _cancelTouchEnd = (e: TouchEvent) => {
    e.stopPropagation();
  };

  _logLogoClick = () => {
    logReferralEvent("wispformLogoClicked");
  };

  _renderWispformLogo = () => {
    //dont show wispform branding when user paid for wispform
    if (this.props.isFormFromProOrHigherPlan) {
      return null;
    } else {
      return (
        <a
          onClick={this._logLogoClick}
          href={wispfromLandingPage}
          className="Questions-Logo-Link"
          onTouchEnd={this._cancelTouchEnd}
        >
          <img className="Questions-Logo" src={logo} />
        </a>
      );
    }
  };

  showSubmitArea() {
    if (isMobile()) {
      if (
        this.props.questions &&
        this.props.currentQuestionNumber > this.props.questions.length
      ) {
        return (
          <div
            onTouchEnd={this.handleSubmitAreaClick.bind(this)}
            className="Form-Submit"
          >
            <SubmitButton onClick={this.props.submitForm} />
            <div className="Questions-divider" />
            {this._renderWispformLogo()}
          </div>
        );
      } else {
        return <div />;
      }
    } else {
      return (
        <div
          onTouchEnd={this.handleSubmitAreaClick.bind(this)}
          className="Form-Submit"
        >
          <SubmitButton onClick={this.props.submitForm} />
          <div className="Questions-divider" />
          {this._renderWispformLogo()}
        </div>
      );
    }
  }

  _handleKeyDown = event => {
    const tabKeycode = 9;
    if (event.keyCode === tabKeycode) {
      if (event.shiftKey) {
        this.props.previousQuestion && this.props.previousQuestion();
      } else {
        this.props.nextQuestion && this.props.nextQuestion();
      }
    }
  };

  render() {
    return [
      <div onKeyDown={this._handleKeyDown} className="Questions">
        {this.renderQuestions()}
      </div>,
      this.showSubmitArea()
    ];
  }
}

export default withStyles(Questions);
