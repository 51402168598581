//@flow

/*
    withFeatureCheck enhences FormPageContainer

    It checks if a form uses pro feature and 
    if publisher of the form is a paid account.

    If not, don't display the form
*/
import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  getVisibleQuestions,
  hasCustomizedThankyouPage,
  isFormFromPaidPlan,
  isFormFromDelinquentAccount,
  hasPaymentQuestion,
  hasSubscriptionQuestion,
  hasAnyJumpLogic,
  hasScoreCalculation,
  hasUnwhitelistedBackgroundImage,
  hasLogo,
  hasHiddenFieldVariables,
  hasAnswerPipe
} from "../../Pages/Form/States/Answers/selectors";
import { toArray } from "../../Library/Util";
import PrivatePage from "./PrivatePage";
import UpgradeSnackBar from "./UpgradeSnackBar";

type State = {|
  canPreview: boolean
|};
type InjectedProps = {||};

function withFeatureCheck<PassedProps: Object>(
  WrappedComponent: React.ComponentType<PassedProps>
): React.ComponentType<$Diff<PassedProps, InjectedProps>> {
  return class Wrapper extends React.Component<PassedProps, State> {
    state = {
      canPreview: false
    };

    componentWillMount() {
      window.addEventListener("message", event => {
        if (event.data.enablePreview === true) {
          this.setState({
            canPreview: true
          });
        }
      });
    }

    _hasPaymentQuestion = () => {
      return this.props.hasPaymentQuestion;
    };

    _hasJumpLogic = () => {
      return this.props.hasAnyJumpLogic;
    };

    _hasScoreCalculation = () => {
      return this.props.hasScoreCalculation;
    };

    _hasCustomLogo = () => {
      return this.props.hasLogo;
    };

    _hasUnwhitelistedBackgroundImage = () => {
      return this.props.hasUnwhitelistedBackgroundImage;
    };

    _hasProFeature = () => {
      if (
        this.props.hasCustomizedThankyouPage ||
        this._hasMoreThan10Questions() ||
        this._hasPaymentQuestion() ||
        this._hasJumpLogic() ||
        this._hasScoreCalculation() ||
        this._hasCustomLogo() ||
        this._hasUnwhitelistedBackgroundImage() ||
        this._isQuizOrCalculator() ||
        this.props.hasHiddenFieldVariables ||
        this.props.hasAnswerPipe ||
        this.props.hasSubscriptionQuestion
      ) {
        return true;
      }
      return false;
    };

    _isQuizOrCalculator = () => {
      if (
        this.props.formConfig &&
        (this.props.formConfig.displayName === "Outcome Quiz" ||
          this.props.formConfig.displayName === "Calculator")
      ) {
        return true;
      } else {
        return false;
      }
    };

    _hasMoreThan10Questions = () => {
      const le = toArray(this.props.formQuestions).length;
      return toArray(this.props.formQuestions).length > 10;
    };

    /**
     *  temporarily add this to allow publishers who created forms before
     *  upgrade experience to continue display their form
     */
    _hasMoreThan25Questions = () => {
      const le = toArray(this.props.formQuestions).length;
      return toArray(this.props.formQuestions).length > 25;
    };

    _isPaidAccount = () => {
      return (
        this.props.isFormFromPaidPlan || this.props.isFormFromDelinquentAccount
      );
    };

    _canPreview = () => {
      return this.state.canPreview;
    };

    render() {
      if (this._hasProFeature() && !this._isPaidAccount()) {
        if (this._canPreview()) {
          return [
            <UpgradeSnackBar />,
            <WrappedComponent
              {...this.props}
              isInPreview={this.state.canPreview}
            />
          ];
        } else {
          return <PrivatePage />;
        }
      } else {
        return (
          <WrappedComponent
            {...this.props}
            isInPreview={this.state.canPreview}
          />
        );
      }
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    formQuestions: getVisibleQuestions(state.Answers),
    hasCustomizedThankyouPage: hasCustomizedThankyouPage(state.Answers),
    isFormFromPaidPlan: isFormFromPaidPlan(state.Answers),
    isFormFromDelinquentAccount: isFormFromDelinquentAccount(state.Answers),
    hasPaymentQuestion: hasPaymentQuestion(state.Answers),
    hasSubscriptionQuestion: hasSubscriptionQuestion(state.Answers),
    hasAnyJumpLogic: hasAnyJumpLogic(state.Answers),
    hasScoreCalculation: hasScoreCalculation(state.Answers),
    hasUnwhitelistedBackgroundImage: hasUnwhitelistedBackgroundImage(
      state.Answers
    ),
    hasLogo: hasLogo(state.Answers),
    hasHiddenFieldVariables: hasHiddenFieldVariables(state.Answers),
    hasAnswerPipe: hasAnswerPipe(state.Answers)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFeatureCheck
);
