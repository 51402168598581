//@flow strict-local
import React, { Component } from "react";
import "./style.css";
import { DropdownButton } from "react-bootstrap";
import { MenuItem } from "react-bootstrap";
import { domainName } from "../../../config";
import { browserHistory } from "react-router";
import { logNewUserOnboarding } from "../../../Library/Logger";
import PropTypes from "prop-types";
import {
  isEditorInOrganization,
  isPremium,
  isPro,
  isBusiness,
  isEssential
} from "../../../Library/UserInfo";

type Props = {|
  publisher?: string
|};
class LogoutButton extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  _onLogOutButtonClick = () => {
    logNewUserOnboarding("logOutButtonInHeaderClicked");
    localStorage.setItem("token", "");
    localStorage.setItem("refreshToken", "");
    localStorage.setItem("name", "");
    localStorage.setItem("plan", "");
  };

  _isPremiumPlan = () => {
    return localStorage.getItem("plan") === "PremiumWisptable";
  };

  _onSettingButtonClick = () => {
    logNewUserOnboarding("settingButtonInHeaderClicked");
    browserHistory.push("/setting/account");
  };

  _onPlanButtonClick = () => {
    logNewUserOnboarding("upgradeButtonInHeaderClicked");
    browserHistory.push("/setting/checkout");
  };

  _onHowToButtonClick = () => {
    logNewUserOnboarding("howtoButtonInHeaderClicked");
    var win = window.open("https://wispform.com/guide", "_blank");
    win.focus();
  };

  render() {
    return (
      <DropdownButton
        pullRight
        id="Logout Button"
        className="header-bar-user-dropdown"
        title={
          <div className="header-bar-dropdown-content">
            <div className="header-bar-user-box">
              <div className="header-bar-user-box-styling">
                {this.props.publisher
                  ? this.props.publisher.charAt(0).toUpperCase()
                  : ""}
              </div>
            </div>
            <div className="header-bar-user-text">{this.props.publisher}</div>
          </div>
        }
      >
        <MenuItem eventKey="1" onClick={this._onSettingButtonClick}>
          Account{" "}
          <span className="header-bar-dropdown-plan-box">
            {localStorage.getItem("plan")}
          </span>
        </MenuItem>
        {this._isPremiumPlan() && (
          <MenuItem eventKey="2" href={`/wisptable`}>
            Wisptable
          </MenuItem>
        )}

        {!isEditorInOrganization() &&
          !isPremium &&
          !isPro &&
          !isBusiness &&
          !isEssential && (
            <MenuItem eventKey="2" onClick={this._onPlanButtonClick}>
              {" "}
              Upgrade
            </MenuItem>
          )}

        <MenuItem eventKey="2" onClick={this._onHowToButtonClick}>
          {" "}
          How-to Guides
        </MenuItem>

        <MenuItem
          eventKey="3"
          onClick={this._onLogOutButtonClick}
          href={`https://${domainName}/sign_out`}
        >
          Log out
        </MenuItem>
      </DropdownButton>
    );
  }
}

LogoutButton.propTypes = {
  publisher: PropTypes.string.isRequired
};

export default LogoutButton;
