//@flow
import * as React from "react";
import "./style.css";
import { UICoreModal, UICoreText, UICoreButton, UICoreBox } from "../UICore";
import Logo from "../Logo/Logo";
import { browserHistory } from "react-router";
import { logMonetizationEvent } from "../../Library/Logger";

type Props = {|
  onModalDismiss: () => void,
  featureName: string,
  onTryButtonClick: ?() => void
|};
type State = {||};
class UpgradeModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  redirectCheckoutPage = () => {
    //$FlowFixMe
    logMonetizationEvent("upgradeClicked-" + this.props.featureName);
    browserHistory.push("/setting/checkout");
    this.props.onModalDismiss();
  };

  _handleTryButtonClick = () => {
    this.props.onTryButtonClick && this.props.onTryButtonClick();

    this.props.onModalDismiss();
  };

  render() {
    return (
      <UICoreModal
        onDismiss={this.props.onModalDismiss}
        size="md"
        header={`${this.props.featureName} is a paid feature`}
        body={
          <UICoreBox
            paddingTop="sm"
            paddingBottom="sm"
            paddingLeft="sm"
            paddingRight="sm"
          >
            <UICoreBox
              direction="row"
              alignItems="center"
              justifyContent="center"
              marginBottom="md"
              marginTop="sm"
            >
              <Logo />
              <UICoreBox paddingLeft="xm">
                <UICoreText size="lg" weight="bold">
                  Wispform
                </UICoreText>
              </UICoreBox>
              <UICoreBox>
                <UICoreText size="lg" weight="bold" color="lightBlue">
                  Essential
                </UICoreText>
              </UICoreBox>
            </UICoreBox>
            <UICoreText color="darkGray" overflow="wrap">
              Click{" "}
              <span style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                upgrade
              </span>{" "}
              and subscribe to Wispform Essential or above to get custom thank
              you screen, logic jump, unlimited questions, outcome quiz,
              calculator and more for only $10 per month.
            </UICoreText>

            <UICoreBox paddingTop="sm">
              <UICoreText color="darkGray" overflow="wrap">
                You can choose{" "}
                <span style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                  continue
                </span>{" "}
                to try this feature without upgrading. However, only you will be
                able to view and respond to this form.
              </UICoreText>
            </UICoreBox>

            <UICoreBox paddingTop="sm">
              <UICoreText color="darkGray" overflow="wrap">
                You can also{" "}
                <span style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                  close
                </span>{" "}
                this popup to continue without this feature.
              </UICoreText>
            </UICoreBox>

            {/* <UICoreBox paddingTop="xm">
              <UICoreText color="Grey" overflow="wrap">
                You can upgrade to Wispform Pro to make your form accessible.
              </UICoreText>
            </UICoreBox> */}
            <UICoreBox justifyContent="end" direction="row" paddingTop="lg">
              <UICoreButton onClick={this.redirectCheckoutPage}>
                Upgrade
              </UICoreButton>
              <UICoreBox marginLeft="xm">
                <UICoreButton
                  onClick={this._handleTryButtonClick}
                  color="lightBlue"
                >{`Continue`}</UICoreButton>
              </UICoreBox>
            </UICoreBox>
          </UICoreBox>
        }
      />
    );
  }
}
export default UpgradeModal;
