//@flow
import * as React from "react";
import { connect } from "react-redux";
import withNotifications, {
  type InjectedProps
} from "../../../../../Helper_HOC/withNotifications";
import { browserHistory } from "react-router";
import { logFeatureEvent } from "../../../../../Library/Logger";
import { isPaidUser } from "../../../../../Library/UserInfo";
import {
  UICoreText,
  UICoreImage,
  UICoreBox,
  UICoreButton,
  UICoreModal,
  UICoreInput,
  UICoreDropdown
} from "../../../../../Component/UICore";
import {
  getRequest,
  postRequest,
  deleteRequest
} from "../../../../../Library/Request";
import { resolveInTime } from "../../../../../Library/Util";
import { getUrlVars } from "../../../../../Library";

type Props = {| ...InjectedProps |};

type State = {|
  notifications: any
|};

class EmailSection extends React.Component<Props, State> {
  state: State = {
    notifications: {}
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    let form_id = getUrlVars()["id"];
    getRequest("api/v1/forms/" + form_id + "/notifications", {
      form_uuid: form_id
    }).then(notification => {
      if (notification.data.data && notification.data.data.notifications)
        this.setState({
          notifications: notification.data.data.notifications
        });
      else {
        this.setState({ notifications: {} });
      }
    });
  }

  _handleFrequencyUpdate = (targetAnswer: string) => {
    if (!isPaidUser()) {
      this.props.showNotification(
        "primary",
        "Adding redirect URL on submission is a paid feature",
        "Upgrade to any paid plan redirect users on submission",
        "Upgrade",
        () => {
          logFeatureEvent("configurePage/addRedirectURLUpgradeClicked");
          browserHistory.push("/setting/checkout");
        }
      );
    } else {
      let new_notifications = this.state.notifications;
      new_notifications.self_notification_frequency = targetAnswer;
      this.setState({ notifications: new_notifications });
      let form_id = getUrlVars()["id"];
      postRequest("api/v1/forms/" + form_id + "/notifications", {
        form_uuid: form_id,
        notifications: this.state.notifications
      });
    }
  };

  render() {
    return (
      <UICoreBox padding="sm" alignItems="start">
        <UICoreBox alignItems="start" width="100%">
          <UICoreBox paddingBottom="sm">
            <UICoreText color="black" size="md" weight="bold">
              Self Notification
            </UICoreText>
          </UICoreBox>
          <UICoreBox paddingBottom="md">
            <UICoreText overflow="wrap">
              Wispform offers two option for self notification as shown below:
            </UICoreText>
          </UICoreBox>
          <UICoreBox paddingBottom="md">
            <UICoreText overflow="wrap">
              Option 1 (Daily) - we will trigger a daily email that will sum up
              how many responses you got over the past 24 hours.
            </UICoreText>
          </UICoreBox>
          <UICoreBox paddingBottom="md">
            <UICoreText overflow="wrap">
              Option 2 (Each Response) - we will send you an email notification
              for each response you receive. We will include the details of the
              response in this email.
            </UICoreText>
          </UICoreBox>
          <UICoreBox paddingBottom="md">
            <UICoreText weight="bold" color="lightBlue">
              Frequency:
            </UICoreText>
            <UICoreBox paddingTop="sm">
              <UICoreDropdown
                selectChoice={{
                  value: this.state.notifications.self_notification_frequency
                    ? this.state.notifications.self_notification_frequency
                    : "Daily",
                  display: this.state.notifications.self_notification_frequency
                    ? this.state.notifications.self_notification_frequency
                    : "Daily"
                }}
                dropdownMaxHeight={200}
                choices={[
                  { value: "Daily", display: "Daily" },
                  { value: "Each Response", display: "Each Response" }
                ]}
                width="150px"
                dropdownWidth={150}
                onChoiceClick={answer => this._handleFrequencyUpdate(answer)}
              />
            </UICoreBox>
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    configurations: state.Question.configurations
  };
};

export default connect(mapStateToProps)(withNotifications(EmailSection));
